<!-- BODY LAYOUT -->
<template>
  <!-- Centers and horizontally pads the content. Use fluid to fully extend the container -->
  <v-container>
    <v-row class="mt-4">
      <!-- IMAGE ROW -->
      <v-col sm="12" class="text-center">
        <v-img :src="require('@assets/wptrs.png')" contain height="250"></v-img>
      </v-col>
      <!-- WELCOME TEXT -->
      <v-col sm="12" class="text-center">
        <h1 class="text-h4 primary--text mt-6">
          Wyoming Property Tax Refund System
        </h1>
        <h2 class="text-h5 primary--text">Welcome to our site</h2>
        <v-row>
          <v-card
            v-if="currentLuPeriod || errorMessage"
            class="mx-auto my-5 pa-8"
          >
            <div v-if="currentLuPeriod && currentLuPeriod.Id > 0">
              <div v-if="beforePeriod">
                The application period for {{ currentLuPeriod.Period }} refunds
                will open
                {{
                  dayjs(currentLuPeriod.ApplicationOpenDate).format(
                    'MM/DD/YYYY'
                  )
                }}</div
              >
              <div v-if="applicationsAccepted">
                Application submissions for {{ currentLuPeriod.Period }} refunds
                will be accepted until
                {{
                  dayjs(currentLuPeriod.ApplicationDeadline).format(
                    'MM/DD/YYYY'
                  )
                }}
              </div>
              <div v-if="applicationDeadlineExpired">
                <p>
                  Application submissions for
                  {{ currentLuPeriod.Period }} refunds are no longer being
                  accepted. <br />
                  Previously submitted applications can still have information
                  added to them if it has been requested until the processing
                  period ends on
                  {{
                    dayjs(currentLuPeriod.PeriodEndDate).format('MM/DD/YYYY')
                  }}.
                </p>
              </div>
              <div v-if="afterPeriod">
                The application period for {{ currentLuPeriod.Period }} refunds
                has closed
              </div>
            </div>
            <div v-else-if="errorMessage">{{ errorMessage }}</div>
          </v-card>
        </v-row>
      </v-col>
    </v-row>
    <v-row
      ><v-col class="text-center">
        For paper application, brochure, and additional information, please
        visit the
        <a
          href="https://revenue.wyo.gov/divisions/administrative-services/property-tax-refund-program"
          target="_blank"
          >Property Tax Refund Program Homepage</a
        ></v-col
      ></v-row
    >
    <v-divider class="my-10 primary"></v-divider>
    <v-row>
      <v-col cols="12" sm="6" md="4">
        <v-card min-height="100" flat>
          <v-card-title class="primary--text text-h5">
            What you will need before you begin
          </v-card-title>
          <v-card-subtitle class="text-h6">
            Documents to Upload:
          </v-card-subtitle>
          <v-card-text>
            <li> Copies of Tax Bill (Available from your County Treasurer) </li>
            <li>
              Copy of Tax Receipts (Available from your County Treasurer)
            </li>
            <li>
              Copies of verification of all income (IRS form 1040, Pension/IRA
              and or Social Security Benefits Letter), for each household
              member.
            </li>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-card min-height="100" flat>
          <v-card-title class="primary--text text-h5">
            New Changes for 2023 Tax Year:
          </v-card-title>
          <v-card-subtitle class="text-h6">
            Please note changes to the program for the 2023 Tax Year:
          </v-card-subtitle>
          <v-card-text>
            <li v-for="th in activeIncomeThresholds">
              If your income is
              <span v-if="th.minIncome"
                >{{ th.minIncome * 100 + '%' }} to
                {{ th.IncomePercentage * 100 + '%' }} of</span
              ><span v-else
                >{{ th.IncomePercentage * 100 + '%' }} or below</span
              >
              the Median Income for your county or the state, the greater of,
              your refund will be calculated on the 75% of your taxes paid not
              to exceed one half of the median residential tax bill for your
              county.
              <span v-if="th.reducedBy"
                >This amount is then reduced by
                {{ th.reducedBy * 100 + '%' }}.</span
              >
            </li>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-card min-height="100" flat>
          <v-card-title class="primary--text text-h5">
            Qualification Requirements
          </v-card-title>
          <!-- <v-card-subtitle class="text-h6"> more here... </v-card-subtitle> -->
          <v-card-text>
            <li> Wyoming resident for at least five years </li>
            <li> Primary residence only </li>
            <li> Meet Income requirements (varies by County) </li>
            <li>
              Total assets are LESS than
              {{ currentLuPeriod.AssetMaximum | formatMoney }} PER adult
              household member
            </li>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<!-- SCRIPTS -->
<script>
// import logo from '@assets/wyoming-vector-logo.svg'
import dayjs from 'dayjs'
import { get, sync, commit, call } from 'vuex-pathify'
export default {
  components: {
    // logo,
  },
  data: () => ({
    dayjs,
    // logo,
  }),
  computed: {
    ...get('luPeriod', ['currentLuPeriod', 'errorMessage']),
    ...get('luIncomeThreshold', ['currentLuIncomeThresholds']),
    withinPeriod() {
      let period = this.currentLuPeriod
      let withinPeriod =
        dayjs(period.ApplicationOpenDate) <= dayjs() &&
        dayjs(period.PeriodEndDate) >= dayjs()
      return withinPeriod
    },
    beforePeriod() {
      let period = this.currentLuPeriod
      return dayjs(period.ApplicationOpenDate) > dayjs()
    },
    applicationDeadlineExpired() {
      let period = this.currentLuPeriod
      return dayjs(period.ApplicationDeadline) < dayjs() && !this.afterPeriod
    },
    applicationsAccepted() {
      let period = this.currentLuPeriod
      return (
        dayjs(period.ApplicationOpenDate) <= dayjs() &&
        dayjs(period.ApplicationDeadline) >= dayjs()
      )
    },
    afterPeriod() {
      let period = this.currentLuPeriod
      return dayjs(period.PeriodEndDate) < dayjs()
    },
    activeIncomeThresholds() {
      if (this.currentLuIncomeThresholds?.length > 0) {
        var list =
          this.currentLuIncomeThresholds
            ?.filter((x) => true)
            ?.sort((a, b) => a.IncomePercentage - b.IncomePercentage) || []
        return list.map((x, i) => {
          var min =
            i == 0 ? null : (list[i - 1].IncomePercentage * 100 + 1) / 100
          return {
            ...x,
            minIncome: min,
            reducedBy: 1 - x.RefundPercentage,
          }
        })
      } else {
        return []
      }
    },
  },
  methods: {
    ...call('luPeriod', ['loadCurrentLuPeriod']),
    loadCurrentLuIncomeThresholds: call(
      'luIncomeThreshold/loadCurrentLuIncomeThresholds'
    ),
  },
  created() {
    this.loadCurrentLuPeriod()
    this.loadCurrentLuIncomeThresholds()
  },
}
</script>

<!-- STYLES -->
<style>
.theme-color {
  fill: white !important;
}
</style>
